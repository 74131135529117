



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InteractPageSection } from '@/types';

@Component({})
export default class QuestionYesNoElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;

  public selection = '';

  public switches: Array<boolean> = [];

  private rules: Array<any> = [(v: any) => !!(v+1) || 'Required'];

  private updateInputParameters() {
    this.$emit('updateInputParameters', this.section.id, this.selection);
  }

  private updateSwitchParameters(id: string, label: string) {
    this.$emit('updateInputParameters', id, label);
  }

  private async clickedToContinue(choiceId: string, btnId: string) {
    await this.$emit('updateInputParameters', choiceId, btnId);
    await this.$emit('submit');
  }

  private get getType() {
    let type = 'radio';
    this.section.sectionChoices.forEach(choice => {
      if(choice.choiceSwitch) {
        type = 'switch'
      }
      else if( choice.clickToContinue) {
        type = 'button'
      }
    });
    return type;
  }

  mounted() {
    this.switches = this.section.sectionChoices.map(choice => {
      return !!Number.parseInt(choice.choiceValue);
    });
    this.selection = this.section.sectionChoices[0].choiceOption?.id || '';
    this.$emit('updateInputParameters', this.section.id, this.selection);
  }
}
