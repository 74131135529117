

























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const messages = namespace('Messages');

interface MenuItem {
  title: string;
  nlpText: string;
  icon?: string;
}

@Component

export default class NavButtonDropDown extends Vue {
  @messages.Action
  private returnToNlp!: (backToNlpText: string) => void;

  @messages.Action
  private initQuiqChat!: () => void;

  private readonly menuItems: Array<MenuItem> = [
    {
      title: 'Start Over',
      nlpText: 'trigger_restart'
    },
    {
      title: 'Provide Feedback',
      nlpText: 'trigger_survey'
    },
    {
      title: 'Email Chat Transcript',
      nlpText: 'trigger_transcript'
    },
    {
      title: 'Speak to an Agent',
      nlpText: 'trigger_escalation'
    }
  ];

  private clicked(nlpText: string) {
    this.returnToNlp(nlpText);
  }
}

