






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Message } from '@/types';
import { namespace } from 'vuex-class';
import FormResponse from '@/components/FormResponse.vue';
import BotResponse from '@/components/BotResponse.vue';
import QuiqRegForm from '@/components/QuiqRegForm.vue';

const messages = namespace('Messages');

@Component({
  components: { FormResponse, BotResponse, QuiqRegForm }
})

export default class MessageBubble extends Vue {
  @Prop({ required: true }) readonly message!: Message;
  @Prop({ required: true }) readonly index!: number;

  @messages.Action
  private addMessage!: (message: Message) => void;

  private show = false;

  private formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  }

  mounted() {
    this.show = true;
  }
}

