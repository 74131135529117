import axios from 'axios';
import {Message, RegistrationFormField} from '@/types';
import {uuid} from 'uuidv4';
import store from '@/store';
import QuiqSocket from 'quiq-socket';

export default class QuiqAPI {
  private readonly baseUrl = 'https://jacada.goquiq.com';
  private readonly contactPoint = 'service';
  private readonly clientId = uuid();
  private readonly clientVersion = '0.1.0';
  private readonly timezone = 'US/Eastern';

  private ready = false;

  private accessToken!: string;
  private accessTokenId!: string;
  private registrationFormVersionId!: string;
  private lastMid!: string;
  private quiqWebSocket!: QuiqSocket;

  private async generateAccessToken() {
    const url = this.baseUrl + '/api/v1/token/generate';
    const headers = {
      "X-Quiq-Client-Id": this.clientId,
      "X-Quiq-Client-Version": this.clientVersion
    };
    const data = {};
    const res = await axios.post(url, data,{headers: headers});
    this.accessToken = res.data.accessToken;
    this.accessTokenId = res.data.tokenId;
  }

  public async init() {
    await this.generateAccessToken();
    const url = `${this.baseUrl}/api/v1/messaging/chat/${this.contactPoint}/configuration`;
    const headers = {
      "X-Quiq-Client-Id": this.clientId,
      "X-Quiq-Client-Version": this.clientVersion,
      "X-Quiq-Time-Zone": this.timezone,
      "X-Quiq-Referrer": window.location.href
    }

    const res = await axios.get(url, {headers: headers});
    this.registrationFormVersionId = res.data.registrationFormVersionId;
    const form = res.data.registrationForm.fields as Array<RegistrationFormField>;
    const formMessage: Message = {
      source: 'QUIQ',
      timestamp: new Date().getTime(),
      type: 'QUIQ_REG',
      quiqRegistrationForm: form
    };

    this.ready = true;
    await store.dispatch('Messages/addQuiqMessage', formMessage);
  }

  public postMessage(msg: string) {
    if(this.ready) {
      const url = `${this.baseUrl}/api/v1/messaging/chat/${this.contactPoint}/send-message`;
      const data = {text: msg, responseMessageId: this.lastMid};
      const headers = {
        "X-Quiq-Client-Id": this.clientId,
        "X-Quiq-Client-Version": this.clientVersion,
        "X-Quiq-Time-Zone": this.timezone,
        "X-Quiq-Referrer": window.location.href,
        'X-Quiq-Access-Token': this.accessToken
      }
      axios.post(url, data, {headers: headers}).catch(e => {throw e});
    } else {
      console.error('Quiq not initialized');
    }
  }

  public async postRegistration(form: object) {
    if(this.ready) {
      const url = `${this.baseUrl}/api/v1/messaging/chat/${this.contactPoint}`;
      const data = {
        form: form,
        formVersionId: this.registrationFormVersionId
      };
      const headers = {
        'X-Quiq-Client-Id': this.clientId,
        'X-Quiq-Client-Version': this.clientVersion,
        'X-Quiq-Time-Zone': this.timezone,
        'X-Quiq-Referrer': window.location.href,
        'X-Quiq-Access-Token': this.accessToken
      };
      const res1 = await axios.post(url + '/register', data, { headers: headers }).catch(e => {
        throw e;
      });

      this.lastMid = res1.data.id;

      // this.postMessage('Hello there I am a test');

      const res = await axios.get(url + '/socket-info', {headers: headers});
      this.quiqWebSocket = new QuiqSocket()
        .withURL('wss://' + res.data.url + '/' + this.accessTokenId)
        .addEventListener('message', message => {
          console.log(message);
        });

      this.quiqWebSocket.connect();
    } else {
      console.error('Quiq not initialized');
    }
  }

  public postTyping(typing: boolean) {
    if(this.ready) {
      const url = `${this.baseUrl}/api/v1/messaging/chat/${this.contactPoint}/typing`;
      const data = { typing: typing };
      const headers = {
        'X-Quiq-Client-Id': this.clientId,
        'X-Quiq-Client-Version': this.clientVersion,
        'X-Quiq-Time-Zone': this.timezone,
        'X-Quiq-Referrer': window.location.href,
        'X-Quiq-Access-Token': this.accessToken
      };
      axios.post(url, data, { headers: headers }).catch(e => {
        throw e;
      });
    } else {
      console.error('Quiq not initialized');
    }
  }
}