import { render, staticRenderFns } from "./QuestionYesNoElement.vue?vue&type=template&id=660b4892&scoped=true&"
import script from "./QuestionYesNoElement.vue?vue&type=script&lang=ts&"
export * from "./QuestionYesNoElement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660b4892",
  null
  
)

export default component.exports