import Vue from 'vue'
import Vuex from 'vuex'
import InteractChatConfig from '@/store/modules/InteractChatConfig'
import Messages from '@/store/modules/Messages'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    InteractChatConfig,
    Messages,
  }
})
