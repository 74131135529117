import { Theme } from '@/types';

const Themes: Array<Theme> = [
  {
    name: 'FlBlue',
    light: {
      primary: '#1477d7',
      secondary: '#ffd400',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Dormakaba',
    logo: 'https://creative.jacada.com/client_assets/dorma_kaba/image.png',
    light: {
      primary: '#003594',
      secondary: '#e4002b',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Ascend',
    logo: 'https://recruiting.ultipro.com/ASC1003/JobBoard/049d6c4d-90bc-4150-85cc-8d194d0a68ee/Styles/GetLargeHeaderLogo?brandId=4588ce63-2ddf-476f-93dc-f05df208b402&m=636089528997620000',
    light: {
      primary: '#0363ad',
      secondary: '#e4002b',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'UnitedRentals',
    logo: 'https://psjcd.s3.amazonaws.com/United+Rental/United_Rental_Logo.svg',
    light: {
      primary: '#0050aa',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Caesars',
    logo: 'https://www.caesars.com/etc.clientlibs/settings/wcm/designs/caesars/images/resources/logos/crw-long-logo.png',
    light: {
      primary: '#141414',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'InComm',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Walmart_Spark.svg/1925px-Walmart_Spark.svg.png',
    light: {
      primary: '#0071dc',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'InComm2',
    logo: 'https://www.bluebird.com/content/dam/bluebird/bb-logo.svg',
    light: {
      primary: '#0071dc',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'PremierHealthcare',
    logo: 'https://jacada-demo.s3.amazonaws.com/JMA/DemoServer/Demo+Center/Premier+Healthcare+Logo.png',
    light: {
      primary: '#3D3935',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Stanley',
    logo: 'https://jacada-demo.s3.amazonaws.com/JMA/DemoServer/Demo+Center/black+BandDLogo.png',
    light: {
      primary: '#ff6a13',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Teams',
    logo: 'https://pafeblobprodpn.blob.core.windows.net/20220307t000000zc361d67c3dfa457d80908343968fd6f6/teamsOutlineIcon.png?sv=2018-03-28&sr=c&sig=tLV3PEzRMaXWTUp7wQN%2BNd7bbSaaMmI8%2FpVO6%2B2yLg4%3D&se=2022-04-28T05%3A46%3A41Z&sp=rl',
    light: {
      primary: '#2F2F4A',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'Premier',
    logo: 'https://jacada-demo.s3.amazonaws.com/JMA/DemoServer/Demo+Center/Premier+Logo+gray.png',
    light: {
      primary: '#9E005D',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
  {
    name: 'PremierGray',
    logo: 'https://jacada-demo.s3.amazonaws.com/JMA/DemoServer/Demo+Center/Premier+Logo+gray.png',
    light: {
      primary: '#76777A',
      secondary: '#f99222',
      accent: '#05b9c9',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
    }
  },
];

export default Themes;