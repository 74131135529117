import Pubnub from 'pubnub';
import _ from 'underscore';
import { Message, InteractConfig } from '@/types';

export default class PubnubApi {
  private myPubnub: Pubnub;
  private channels: Array<any> = [];
  private readonly listener: Array<any> = [];

  constructor() {
    try {
      this.myPubnub = new Pubnub({
        publishKey: 'pub-c-9ef8635f-8eae-4a07-adb8-515edd926442',
        subscribeKey: 'sub-c-2ebd149c-7e1c-11e5-9e96-02ee2ddab7fe',
        ssl: true,
      });

      this.channels = [];
      this.listener = [];

      this.myPubnub.addListener({
        message: (m: any) => {
          _.each(this.listener, (listen: any) => {
            if (listen.channel === m.channel) {
              listen.cb(m);
            }
          });

        }
      });
    } catch (ex) {
      console.error('pubnub constructur', ex)
    }
  }

  public listen(channel: string, cbFunction: (message: any) => void) {

    try {
      this.listener.push({
        channel: channel,
        cb: cbFunction
      });
    } catch (ex) {
      console.error('listen', ex);
    }


  }

  public subscribe(channel: string) {

    console.log('Subscribe');
    try {
      const currentSize = this.channels.length;
      this.channels = _.union(this.channels, [channel]);
      const newSize = this.channels.length;
      if (currentSize != newSize) {
        this.myPubnub.subscribe({
          channels: this.channels
        });
      }
    } catch (ex) {
      console.error('subscribe', ex);
    }


  }

  public unsubscribe(channel: any) {

    try {
      this.myPubnub.unsubscribe({
        channels: [channel]
      });
      this.channels = _.without(this.channels, [channel]);
    } catch (ex) {
      console.error('unsbuscribe', ex);
    }


  }

}