




















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const interactConfig = namespace('InteractChatConfig');

@Component({})
export default class PopoutDrawer extends Vue {
  @interactConfig.State
  public drawerOpen!: boolean;
  @interactConfig.State
  public iframeSource!: string;
  @interactConfig.Action
  public resetDrawer!: () => void;

  private open = false;

  @Watch('drawerOpen')
  drawerChanged(newDrawerOpen: boolean) {
    this.open = newDrawerOpen;
  }

  private receiveMessage(e: MessageEvent) {
    if(e.data.name === 'Close_Script') {
      this.resetDrawer();
    }
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage)
  }

  destroy() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
