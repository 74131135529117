// store/modules/InteractChatConfig.ts
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { InteractConfig } from '@/types.ts';

type environment = 'Dev' | 'Test' | 'Prod';

@Module({ namespaced: true })
class InteractChatConfig extends VuexModule {

  public config: InteractConfig = {
    tenantId: 'nlpdemosdf',
    domainName: 'DCM_Telco',
    apiKey: '2101e4a4-8cd2-4413-b389-a276077e2306',
    environment: 'Dev',
    mode: 'StandAlone'
  };

  @Mutation
  public setChatSessionId(chatSessionId: string): void {
    this.config.chatSessionId = chatSessionId;
  }

  @Action
  public updateChatSessionId(chatSessionId: string): void {
    this.context.commit('setChatSessionId', chatSessionId);
  }

  @Mutation
  public setChatSessionUniqueId(chatSessionUniqueId: string): void {
    this.config.chatSessionUniqueId = chatSessionUniqueId;
  }

  @Action
  public updateChatSessionUniqueId(chatSessionUniqueId: string): void {
    this.context.commit('setChatSessionUniqueId', chatSessionUniqueId);
  }

  @Mutation
  public setApiKey(apiKey: string): void {
    this.config.apiKey = apiKey;
  }

  @Action
  public updateApiKey(apiKey: string): void {
    this.context.commit('setApiKey', apiKey);
  }

  @Mutation
  public setTenantId(tenantId: string): void {
    this.config.tenantId = tenantId;
  }

  @Action
  public updateTenantId(tenantId: string): void {
    this.context.commit('setTenantId', tenantId);
  }

  @Mutation
  public setDomainName(domainName: string): void {
    this.config.domainName = domainName;
  }

  @Action
  public updateDomainName(domainName: string): void {
    this.context.commit('setDomainName', domainName);
  }

  @Mutation
  public setEnvironment(environment: environment): void {
    this.config.environment = environment;
  }

  @Action
  public updateEnvironment(environment: environment): void {
    this.context.commit('setEnvironment', environment);
  }

  @Mutation
  public setConfig(config: InteractConfig): void {
    const tempThisConfig = this.config as any;
    const tempConfig = config as any;

    Object.keys(tempConfig).forEach(key => {
      tempThisConfig[key] = tempConfig[key];
    });

    this.config = tempThisConfig;
  }

  @Action
  public updateConfig(config: InteractConfig): void {
    this.context.commit('setConfig', config);
  }

  public drawerOpen = false;

  @Mutation
  public setDrawerOpen(drawerOpen: boolean): void {
    this.drawerOpen = drawerOpen;
  }

  @Action
  public updateDrawerOpen(drawerOpen: boolean): void {
    this.context.commit('setDrawerOpen', drawerOpen);
  }

  public iframeSource = '';

  @Mutation
  public setIframeSource(iframeSource: string): void {
    this.iframeSource = iframeSource;
  }

  @Action
  public updateIframeSource(iframeSource: string): void {
    this.context.commit('setIframeSource', iframeSource);
  }

  @Action
  public resetDrawer(): void {
    this.context.commit('setIframeSource', '');
    this.context.commit('setDrawerOpen', false);
  }
}

export default InteractChatConfig;