import axios from 'axios';
import {Message, InteractConfig, Feedback, thinkingHTML} from '@/types';
import {uuid} from 'uuidv4';
import store from '@/store';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import {InteractSocketApi} from '@/api/InteractSocketApi';
import PubnubApi from '@/api/PubnubApi';

export class InteractAIAPI {
    private baseUrl = 'https://gointeract.io/interact/version/1/account/';
    private http = 'https';
    private interactionInstanceId = '';
    private userId = '';
    private config: InteractConfig;

    constructor(config: InteractConfig) {
        this.config = config;
    }

    private addThinkingMessage() {
        const thinkingMessage = {
            source: 'BOT',
            timestamp: new Date().getTime(),
            html: thinkingHTML,
            type: 'THINKING'
        };
        store.commit('Messages/appendMessage', thinkingMessage);
    }

    public async init(config: InteractConfig) {
        this.config = config;

        if (!this.config.host) {
            this.config.host = 'gointeract.io';
        }

        if (this.config.useHttp === 'true') {
            console.log('Use http');
            this.http = 'http';
        }

        this.baseUrl = `${this.http}://${this.config.host}/interact/version/1/account/`;

        if (localStorage.getItem('chatbot_userId')) {
            this.userId = localStorage.getItem('chatbot_userId')!;
        } else {
            this.userId = uuid();
            localStorage.setItem('chatbot_userId', this.userId);
        }

        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'application/json',
            'User-Id': this.userId
        };

        const data: any = {
            accountId: this.config.tenantId,
            appkey: this.config.apiKey,
            domainName: this.config.domainName,
            'Environment-Name': this.config.environment
        };

        if (this.config.chatSessionId) {
            console.log('Using Session Context ID');
            headers['session-hint'] = this.config.chatSessionId;
            data.sessionId = this.config.chatSessionId;
        }

        const appSettingsUrl = `${this.http}://${this.config.host}/interact/appkeydata/settings/${this.config.apiKey}?includeAppTranslation=false`;
        const settings = await axios.get(appSettingsUrl, {headers: headers, responseType: "json"});

        if (settings.data.rpaBotRunMode === 'Attended') {
            try {
                const botIdRes = await axios.get('http://127.0.0.1:9002/rpa/bot/id');
                headers["rpa-bot-id"] = botIdRes.data;
            } catch (e) {
                console.warn('No bot available', e);
            }
        }

        console.log('Query string:', window.location.hash);
        const res = await axios.post(
            this.baseUrl + this.config.tenantId + '/conversation/domains/' + this.config.domainName + '/start' + window.location.hash.replace('#/', ''),
            data,
            {
                headers: headers,
                responseType: 'json',
            }
        );

        window.sessionStorage.setItem('jacadaChatSessionId', res.data.sessionId);

        this.config.chatSessionId = res.data.sessionId;
        this.config.chatSessionUniqueId = res.data.instanceUniqueId;
        this.interactionInstanceId = res.data.interactionInstanceId;

        if (res.data.greetingResponse && res.data.pastConversationsMessages.length === 0) {
            const message: Message = {
                source: 'BOT',
                timestamp: new Date().getTime(),
                type: 'GREETING',
            };

            if (res.data.greetingResponse.html) {
                message.html = res.data.greetingResponse.html;
            } else if (res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections) {
                message.pageContent = res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections;
                message.pageHeader = res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentHeader;
            }

            store.commit('Messages/startChat', message);
        } else if (
            res.data.pastConversationsMessages.length > 0 &&
            store.state.InteractChatConfig.config.mode !== 'MultiModal'
        ) {
            console.log('Posting Open chat');
            window.parent.postMessage({
                eventName: 'open_chat'
            }, '*');
            let date = new Date(0);
            res.data.pastConversationsMessages.forEach((msg: any) => {
                const msgDate = new Date(msg.timestamp);
                if (msgDate.toDateString() !== date.toDateString()) {
                    date = msgDate;
                    const dateMessage: Message = {
                        timestamp: msg.timestamp,
                        stringMessage: msgDate.toDateString(),
                        source: 'BOT',
                        type: 'TIME',
                    }
                    store.commit('Messages/appendMessage', dateMessage);
                }

                if (msg.type === 'BOT_MSG') {
                    store.commit('Messages/handleResponse', {data: msg, type: 'PAST'});
                } else if (msg.type === 'USER_MSG') {
                    const message: Message = {
                        timestamp: msg.timestamp,
                        stringMessage: msg.text,
                        source: 'USER',
                        type: 'PAST',
                    };
                    store.commit('Messages/appendMessage', message);
                }
            });

            if (res.data.greetingResponse) {
                const message: Message = {
                    source: 'BOT',
                    timestamp: new Date().getTime(),
                    type: 'GREETING',
                };

                if (res.data.greetingResponse.html) {
                    message.html = res.data.greetingResponse.html;
                } else if (res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections) {
                    message.pageContent = res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections;
                    message.pageHeader = res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentHeader;
                }

                store.commit('Messages/startChat', message);
            }
        }

        if (InteractChatConfig.state.config.mode !== 'StandAlone') {
            const socketApi = new InteractSocketApi(store.state.InteractChatConfig.config);
            try {
                await socketApi.connect()?.then(() => {
                    console.log('Connected to Interact Socket');
                    socketApi.subscribe('chat-' + InteractChatConfig.state.config.chatSessionId, (msg) => {
                        if (
                            store.state.Messages.messages[store.state.Messages.length - 1] &&
                            store.state.Messages.messages[store.state.Messages.length - 1].type === 'THINKING'
                        ) {
                            console.log('Skipping my own message');
                            return;
                        }
                        const receivedMsg = JSON.parse(JSON.parse(msg.body.toString()).data);
                        console.log('Received Socket Message:', receivedMsg);
                        if (receivedMsg.type != 'HANGUP_CALL')
                        store.commit('Messages/handleResponse', receivedMsg);
                    });

                    this.subscribeToCustomerAssistantEvents(socketApi);
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            // at least subscribe to customer assistant events
            const socketApi = new InteractSocketApi(store.state.InteractChatConfig.config);
            await socketApi.connect();

            this.subscribeToCustomerAssistantEvents(socketApi);

        }

        console.log('Creating PubNub Instance', this.config.chatSessionId);
        const pubnub = new PubnubApi();
        pubnub.unsubscribe(this.config.chatSessionId);
        pubnub.subscribe(this.config.chatSessionId!);
        pubnub.listen(this.config.chatSessionId!, (data: any) => {
            const message = data.message;

            try {
                switch (message.command) {
                    case 'trigger_hidden':
                        store.dispatch('Messages/returnToNlp', message.payload.message).then();
                        break;
                    case 'trigger_escalation':
                        console.log('Triggering escalation to', message.payload.chatCarrier);
                        store.dispatch('Messages/escalate', message.payload).then();
                        break;
                    case 'show_text':
                        break;
                    case 'show_text_agent':
                        break;
                }
            } catch (ex) {
                console.error('handleAsynchronousBotResponse', ex);
            }
        });

        if (this.config.startMessage) {
            await store.dispatch('Messages/returnToNlp', this.config.startMessage);
        }
    }

    // public setSessionIds(chatSessionId: string, chatSessionUniqueId: string) {
    //   window.sessionStorage.setItem('jacadaChatSessionId', chatSessionId);
    //
    //   this.config.chatSessionId = chatSessionId;
    //   this.config.chatSessionUniqueId = chatSessionUniqueId;
    // }

    // public setInstanceId(instanceId: string) {
    //   this.interactionInstanceId = instanceId;
    // }

    public subscribeToCustomerAssistantEvents(socketApi: InteractSocketApi) {
        const customerAssistantTopic = '/' + InteractChatConfig.state.config.chatSessionId + '/assistantEvents';
        socketApi.subscribe(customerAssistantTopic, (msg) => {

            if (
                store.state.Messages.messages[store.state.Messages.length - 1] &&
                store.state.Messages.messages[store.state.Messages.length - 1].type === 'THINKING'
            ) {
                console.log('Skipping my own message');
                return;
            }
            const receivedMsg = JSON.parse(msg.body.toString()).data;
            console.log('Received Socket Message:', receivedMsg);

            store.dispatch('Messages/handleCustomerAssistantMessage', receivedMsg);

        })
    }

    public sendMessage(message: Message) {
        this.addThinkingMessage();
        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'application/json',
        };

        const body: any = {
            text: message.stringMessage,
            variables: {
                channel: 'richUI',
                demoChannel: 'richUI',
                "Feedback Collection": JSON.stringify(message.feedback)
            },
        };

        return axios.post(
            this.baseUrl +
            this.config.tenantId +
            '/conversation/domains/' +
            this.config.domainName +
            '?sessionId=' +
            this.config.chatSessionId,
            body,
            {
                headers: headers,
                responseType: 'json',
            }
        );
    }

    public returnToNlp(backToNlpText: string, feedback: Array<Feedback>) {
        if (backToNlpText !== 'receive_evaluation_from_client') {
            this.addThinkingMessage();
        }

        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'application/json',
        };

        const body: any = {
            conversationActionDto: {
                actionName: 'RETURN_TO_NLP',
                actionValues: {
                    backToNlpText: backToNlpText,
                },
            },
            inputParameters: {},
            variables: {
                channel: 'richUI',
                demoChannel: 'richUI',
                "Feedback Collection": JSON.stringify(feedback)
            }
        };

        return axios.post(
            this.baseUrl +
            this.config.tenantId +
            '/conversation/domains/' +
            this.config.domainName +
            '?sessionId=' +
            this.config.chatSessionId,
            body,
            {
                headers: headers,
                responseType: 'json',
            }
        );
    }

    public continueFlow(inputParameters: any, feedback: Array<Feedback>) {
        this.addThinkingMessage();

        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'application/json',
        };

        const body: any = {
            conversationActionDto: {
                actionName: 'CONTINUE_FLOW',
            },
            inputParameters: inputParameters,
            variables: {
                "Feedback Collection": JSON.stringify(feedback)
            }
        };

        return axios.post(
            this.baseUrl +
            this.config.tenantId +
            '/conversation/domains/' +
            this.config.domainName +
            '?sessionId=' +
            this.config.chatSessionId,
            body,
            {
                headers: headers,
                responseType: 'json',
            }
        );
    }

    public goToFlow(flowId: string, feedback: Array<Feedback>) {
        this.addThinkingMessage();

        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'application/json',
        };

        const body: any = {
            conversationActionDto: {
                actionName: 'GO_TO_FLOW',
                actionValues: {
                    flowId: flowId,
                },
            },
            inputParameters: {},
            variables: {
                "Feedback Collection": JSON.stringify(feedback)
            }
        };

        return axios.post(
            this.baseUrl +
            this.config.tenantId +
            '/conversation/domains/' +
            this.config.domainName +
            '?sessionId=' +
            this.config.chatSessionId,
            body,
            {
                headers: headers,
                responseType: 'json',
            }
        );
    }

    public uploadResource(file: File, id: string, headerId: string) {
        console.log('Id in api', id);

        const headers: any = {
            'Tenant-Id': this.config.tenantId,
            'Application-Key': this.config.apiKey,
            'Environment-Name': this.config.environment,
            'Content-Type': 'multipart/form-data',
            'File-Local-Address': encodeURI("" + (new Date).getTime()),
            'Object-Step-Id': headerId,
            'User-Id': this.config.chatSessionUniqueId
        };

        const formData = new FormData();
        formData.append('file', file);

        return axios.post(
            this.baseUrl +
            this.config.tenantId +
            '/interaction/dummy/' +
            this.interactionInstanceId +
            '/' +
            id +
            '/resourceUpload?sessionId=' +
            this.config.chatSessionId,
            formData,
            {
                headers: headers,
                responseType: 'json',
            }
        );
    }


}
