

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InteractPageSection, InteractSectionChoice } from '@/types';

@Component({})
export default class QuestionInputElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;
  @Prop({ required: true }) readonly disabled!: boolean;

  private input = '';
  private inputType = '';

  private rules: Array<any> = [];

  private getInputType(choice: InteractSectionChoice): string {
    if (choice.textInput) {
      this.inputType = choice.textInput.textInputFormat;
      switch (choice.textInput.textInputFormat) {
        case 'TEXT':
          if (choice.textInput.textMask.includes('NumPad')) {
            return 'number';
          }
          return 'text';
        case 'DATE':
          return 'date';
        case 'CURRENCY':
          return 'number';
        case 'PASSWORD':
          return 'password';
        default:
          return 'text';
      }
    }

    return 'text';
  }

  private updateInputParameters() {
    let input = this.input;
    if (this.inputType === 'DATE') {
      const date = new Date(this.input);
      input = date.getTime().toString();
    }

    this.$emit('updateInputParameters', this.section.id, input);
  }

  mounted() {
    if (this.section.sectionChoices[0].textInput!.isMandatory) {
      this.rules.push((v: any) => !!v || 'Required');
    }
    this.input = this.section.sectionChoices[0].choiceValue;
    this.updateInputParameters();

    const el = document.querySelector('#password');
    if (el) {
      el.classList.add('masked');
    }
  }
}
