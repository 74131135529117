








































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { InteractConfig, Message } from '@/types';

const messages = namespace('Messages');
const interactConfig = namespace('InteractChatConfig');

interface ChatInput {
  innerText: string;
  clientHeight: number;
}

@Component({})
export default class UserInput extends Vue {
  @Ref('chatInput') readonly chatInput!: ChatInput;
  @messages.Action
  private addMessage!: (message: Message) => void;

  private messageString = '';
  private inputHeight = 0;

  private enterSendMessage(e: KeyboardEvent): void {
    if (this.messageString.endsWith('\n')) {
      this.messageString = this.messageString.slice(0, -1);
      console.log(this.messageString);
    }
    if (e.key === 'Enter' && this.messageString) {
      // const message: Message = {
      //   timestamp: new Date().getTime(),
      //   stringMessage: this.messageString,
      //   source: 'USER',
      //   type: 'STANDARD',
      // };
      // this.addMessage(message);
      // this.messageString = '';
      // this.$refs.chatInput.innerText = '';
      this.sendMessage();
    }
  }

  private sendMessage(): void {
    console.log('Clicked send:', this.messageString);
    if (this.messageString) {
      const message: Message = {
        timestamp: new Date().getTime(),
        stringMessage: this.messageString,
        source: 'USER',
        type: 'STANDARD',
      };
      this.addMessage(message);
      this.messageString = '';
      this.chatInput.innerText = '';
    }
  }

  private onInput(e: any): void {
    this.inputHeight = this.chatInput.clientHeight + 24;
    this.messageString = e.target.innerText;
  }
}
