

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import NavButtonDropDown from '@/components/NavButtonDropDown.vue';
import UserInput from '@/components/UserInput.vue';
import { namespace } from 'vuex-class';
import ChatView from '@/views/ChatView.vue';
import { InteractConfig } from '@/types';
import PopoutDrawer from '@/components/PopoutDrawer.vue';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';
import Themes from '@/themes';

const messages = namespace('Messages');
const interactConfig = namespace('InteractChatConfig');

@Component({
  components: { PopoutDrawer, ChatView, UserInput, NavButtonDropDown },
})
export default class App extends Vue {
  @interactConfig.State
  private config!: InteractConfig;

  @messages.Action
  public beginChat!: () => void;

  @interactConfig.Action
  public updateConfig!: (config: InteractConfig) => void;

  @interactConfig.Action
  public updateChatSessionId!: (chatSessionId: string) => void;

  private logo = '';

  private setTheme(name: string | (string | null)[]) {
    const theme = Themes.find((t) => {
      return t.name === name;
    });
    if(theme) {
      this.$vuetify.theme.themes.light = theme.light as VuetifyThemeVariant;
      if (theme.logo) {
        this.logo = theme.logo!;
      }
    }
  }

  mounted() {
    this.updateConfig((this.$route.query as unknown) as InteractConfig);
    if(this.$route.query.theme) {
      this.setTheme(this.$route.query.theme);
    }

    if (window.sessionStorage.getItem('jacadaChatSessionId') && !this.$route.query.chatSessionId) {
      this.updateChatSessionId(window.sessionStorage.getItem('jacadaChatSessionId')!);
    }

    setTimeout(() => {
      this.beginChat();
    }, 250);
    //this.beginChat();
  }

  created() {
    if (window.top!=window.self)
    {
      document.getElementsByTagName( 'html' )[0].setAttribute('class', 'no-scroll-bar')
    }
  }
}
