




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData } from 'html-to-image';
import loadjs from 'loadjs';
import axios from 'axios';

@Component({})
export default class ParagraphElement extends Vue {
  @Prop({ required: true }) readonly section: any;

  mounted() {
    document.querySelectorAll('button').forEach((el) => {
      if (el.textContent!.indexOf('payment__') === 0) {
        const text = el.textContent!.replace('payment__', '');
        const payBtn = document.createElement('button');
        payBtn.setAttribute('class', 'AcceptUI');
        payBtn.setAttribute(
          'data-billingAddressOptions',
          JSON.stringify({
            show: true,
            required: false,
          })
        );
        payBtn.setAttribute('data-apiLoginID', '2C9LrhM67w5y');
        payBtn.setAttribute('data-clientKey', '9XQ87RZ4QFqj2X37RT8aDb27n3RvrQ8b56JYTGg6BewyBc3xhQ6AcS9e87nnry4z');
        payBtn.setAttribute('data-acceptUIFormHeaderTxt', 'Card Information');
        payBtn.setAttribute(
          'data-paymentOptions',
          JSON.stringify({
            showCreditCard: true,
            showBankAccount: false,
          })
        );
        payBtn.setAttribute('data-responseHandler', 'onPaymentFormResponse');
        payBtn.textContent = text;

        el.replaceWith(payBtn);

        loadjs(
          ['https://jstest.authorize.net/v1/Accept.js', 'https://jstest.authorize.net/v3/AcceptUI.js'],
          function () {
            console.log('authorize.net Accept libs loaded');
            document.getElementById('AcceptUIContainer')!.style.position = 'fixed';
          }
        );

        window.scrollTo = function () {
          //do noting
        };

        window.onPaymentFormResponse = async (response: any) => {
          console.log('should receive a message:', response);
          await axios.post('https://utilities1.gointeract.io/ivr/api/SimpleCaches/add', {
            key: 'opaqueData',
            itemKey: 'opaqueData',
            itemValue: response.opaqueData,
          });

          await this.$emit('submit');
        };
      }
    });
  }
}
