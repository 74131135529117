






















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Message } from '@/types';
import { namespace } from 'vuex-class';
import MessageBubble from '@/components/MessageBubble.vue';

const messages = namespace('Messages');

@Component({
  components: { MessageBubble },
})
export default class ChatView extends Vue {
  @messages.State
  public messages!: Array<Message>;

  private date = new Date();

  @Watch('messages')
  messageAdded() {
    setTimeout(() => {
      this.$vuetify.goTo(document.getElementById('message-bubble-' + (this.messages.length - 1))!);
    }, 200);
  }
}
