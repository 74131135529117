// store/modules/Messages.ts
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {InteractAIAPI} from '@/api/InteractAIAPI';
import {Message, InteractPageSection, InteractSectionChoice, Feedback, MessageType} from '@/types';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import {AxiosResponse} from 'axios';
import {InteractSocketApi} from '@/api/InteractSocketApi';
import PubnubApi from '@/api/PubnubApi';
import store from '@/store';
import QuiqAPI from '@/api/QuiqAPI';
import Five9ChatService from "@/api/Five9Service";

@Module({namespaced: true})
class Messages extends VuexModule {

    public interactApi: InteractAIAPI = new InteractAIAPI(InteractChatConfig.state.config);
    public quiqApi: QuiqAPI = new QuiqAPI();
    public five9Service?: Five9ChatService;
    public messages: Array<Message> = [];
    private socketApi!: InteractSocketApi;
    private inputHeight!: number;
    private feedback: Array<Feedback> = [];
    private lastIntent = '';
    private messageMode = 'Interact';

    @Action
    public escalate(
        event: {
            chatCarrier: string;
            userName?: string;
            userEmail?: string;
            skill?: string;
        }) {
        switch (event.chatCarrier) {
            case 'Quiq':
                this.context.commit('setMessageMode', 'Quiq');
                this.context.commit('initializeQuiqChat');
                break;
            case 'Five9':
                this.context.commit('setMessageMode', 'Five9');
                this.context.commit('initializeFive9Chat', event);
        }
    }

    @Mutation
    public initializeFive9Chat(
        event: {
            chatCarrier: string;
            userName: string;
            userEmail: string;
            skill: string;
        }) {
        this.five9Service = new Five9ChatService(event.userName, event.userEmail, event.skill);
        this.five9Service.init().then();
    }

    @Mutation
    public initializeQuiqChat() {
        this.quiqApi.init().catch(e => {
            throw e
        });
    }

    @Action
    public postQuiqRegistration(form: object) {
        this.quiqApi.postRegistration(form).catch(e => {
            throw e
        });
    }

    @Mutation
    public setMessageMode(messageMode: string) {
        this.messageMode = messageMode;
    }

    @Action
    public addQuiqMessage(message: Message) {
        this.context.commit('appendMessage', message);
    }

    @Mutation
    public appendFeedback(feedback: Feedback) {
        feedback.intentName = this.lastIntent;
        this.feedback.push(feedback);
    }

    @Action
    public addFeedback(feedback: Feedback) {
        this.context.commit('appendFeedback', feedback);
    }

    @Mutation
    public removeLastMessage(): void {
        this.messages.pop();
    }

    @Mutation
    public appendMessage(message: Message): void {

        this.messages.push(message);


    }

    @Action
    public addMessage(message: Message): void {
        this.context.commit('appendMessage', message);
        if ((message.source === 'USER' || message.source === 'BTN') && this.messageMode === 'Interact') {
            this.interactApi
                .sendMessage(message)
                .then((res) => {
                    console.log(this);
                    this.context.commit('handleResponse', res);
                })
                .catch((err) => {
                    console.log(err);
                    this.context.commit('handleResponse', err);
                });
        } else if (this.messageMode === 'Quiq') {
            this.quiqApi.postMessage(message.stringMessage!);
        } else if (this.messageMode === 'Five9') {
            if (this.five9Service) {
                this.five9Service.sendMessage(message.stringMessage!);
            }
        }
    }

    @Action
    public continueFlow(inputParameters: any): void {

        this.interactApi
            .continueFlow(inputParameters, this.feedback)
            .then((res) => {
                this.context.commit('handleResponse', res);
            })
            .catch((err) => {
                this.context.commit('handleResponse', err);
            });
    }

    @Action
    public goToFlow(flowId: string): void {

        this.interactApi
            .goToFlow(flowId, this.feedback)
            .then((res) => {
                this.context.commit('handleResponse', res);
            })
            .catch((err) => {
                this.context.commit('handleResponse', err);
            });
    }

    @Action
    public returnToNlp(backToNlpText: string): void {

        this.interactApi
            .returnToNlp(backToNlpText, this.feedback).then(res => {
            if (res.data.html || backToNlpText !== 'receive_evaluation_from_client') {
                store.commit('Messages/handleResponse', res);
            }
        })
            .catch((err) => {
                this.context.commit('handleResponse', err);
            });
    }

    @Mutation
    public startChat(message: Message): void {
        this.messages.push(message);
    }

    @Action
    public beginChat(): void {
        console.log('Beginning chat', InteractChatConfig.state.config);
        this.interactApi
            .init(InteractChatConfig.state.config).catch(e => {
            throw e;
        });
    }

    @Action
    public uploadResource(resource: { file: File; id: string; headerId: string }) {
        console.log('Id in store', resource.id);
        this.interactApi.uploadResource(resource.file, resource.id, resource.headerId).then(() => {
            console.log('Resource uploaded');
        }).catch(err => {
            console.error(err);
        });
    }

    @Mutation
    public handleResponse(res: AxiosResponse<any> | any) {

        console.log(res);
        const message: Message = {
            source: 'BOT',
            timestamp: res.data.timestamp || new Date().getTime(),
            type: res.type || 'STANDARD'
        };
        if (res.data.nlpEngineResponse && res.data.nlpEngineResponse.vendorResponse && res.data.nlpEngineResponse.vendorResponse.queryResult.intent.displayName) {
            this.lastIntent = res.data.nlpEngineResponse.vendorResponse.queryResult.intent.displayName;
        }

        if (res.data.interactResponse && res.data.interactResponse.flowInformation && res.data.interactResponse.flowInformation.name) {
            message.flowName = res.data.interactResponse.flowInformation.name;
        }

        if (res.data.interactResponse && res.data.interactResponse.elementResponse && res.data.interactResponse.elementResponse.page.pageNavigation.pageReferenceName) {
            message.responseName = res.data.interactResponse.elementResponse.page.pageNavigation.pageReferenceName;
        }

        const lastMessage = this.messages[this.messages.length - 1];
        if (
            lastMessage.flowName === message.flowName &&
            lastMessage.responseName === message.responseName &&
            lastMessage.html === res.data.html
        ) {
            console.debug('ignoring message.');
            if (this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'THINKING') {
                this.messages.pop();
            }

            return;
        }

        if (res.data && res.data.html) {
            message.html = res.data.html;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'THINKING') {
                this.messages.pop();
            }
            this.messages.push(message);
        } else if (res.data && res.data.interactResponse) {
            message.pageContent = res.data.interactResponse.elementResponse.page.pageContent.contentSections;
            message.pageHeader = res.data.interactResponse.elementResponse.page.pageContent.contentHeader;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'THINKING') {
                this.messages.pop();
            }
            this.messages.push(message);
        } else {
            message.html = `<p>Sorry there was a problem</p>`;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1].type === 'THINKING') {
                this.messages.pop();
            }
            this.messages.push(message);
        }
    }

    @Action
    public handleCustomerAssistantMessage(message: {
        eventType: string;
        text: string;
    }) {
        const newMessage: Message = {
            source: 'BOT',
            timestamp: new Date().getTime(),
            type: 'STANDARD',
            html: message.text
        };

        switch (message.eventType) {
            case 'show_text':
                this.context.commit('appendMessage', newMessage);
                break;
            default:
                console.log('unknown customer assistant message', message);
        }

    }
}

export default Messages;
