import axios from "axios";
import { io } from "socket.io-client";
import store from '@/store';
import {Message, thinkingHTML} from "@/types";

export default class Five9ChatService {
    private readonly baseUrl = '/appsvcs/rs/svc/';
    private readonly socketEndpoint = 'https://five9-server.jacadademos.com';
    private readonly tenantId = 108847;
    private readonly campaignId = 1141365;
    private readonly userEmail: string;
    private readonly skill: string;
    private readonly userName: string;
    private socket: any;
    private hostUrl = 'app.five9.com';
    private tokenId = '';
    private auth = '';
    private farmId = '';

    constructor(userName: string, userEmail: string, skill?: string) {
        this.userName = userName;
        this.userEmail = userEmail;
        this.skill = skill? skill : '';
    }

    public async init() {
        console.log('Initializing Five9 Chat');
        await this.createSession();
        this.subscribeToCallbacks();
    }

    public sendMessage(text: string) {
        axios.post('https://' + this.hostUrl + this.baseUrl + 'conversations/' + this.tokenId + '/messages', {
            "messageType": "TEXT",
            "message": text
        }, {
            headers: {
                "Authorization": this.auth,
                "farmId": this.farmId,
                "Content-Type": "application/json",
                "User-Agent": "API Client"
            }
        });
    }

    public closeChat() {
        axios.post('https://' + this.hostUrl + this.baseUrl + 'conversations/' + this.tokenId + '/terminate', {}, {
            headers: {
                "Authorization": this.auth,
                "farmId": this.farmId,
                "Content-Type": "application/json",
                "User-Agent": "API Client"
            }
        }).catch(err => {
            console.error('Error sending terminate event', err);

        });
    }

    public sendTyping() {
        console.log('This', this);
        axios.put('https://' + this.hostUrl + this.baseUrl + 'conversations/' + this.tokenId + '/messages/typing', {}, {
            headers: {
                "Authorization": this.auth,
                "farmId": this.farmId,
                "Content-Type": "application/json",
                "User-Agent": "API Client"
            }
        }).catch(err => {
            console.error('Error sending typing event', err);

        });
    }

    private async createSession() {
        const result = await axios.post('https://' + this.hostUrl + this.baseUrl + 'auth/anon', {
            tenantName: 'blueruby'
        }).catch(err => {
            console.error('Error generating conversation token');
        });
        if (result) {
            this.tokenId = result.data.tokenId;
            this.auth = 'Bearer-' + this.tokenId;
            this.hostUrl = result.data.metadata.dataCenters[0].apiUrls[0].host;
            this.farmId = result.data.context.farmId;
        }
    }

    private subscribeToCallbacks() {
        debugger;
        console.log('Creating socket');
        this.socket = io(this.socketEndpoint);

        this.socket.on('connect', () => {
            console.log('Five9 Callback Socket connected');
            this.socket.emit('register', {
                tokenId: this.tokenId
            });
        });

        this.socket.on('registered', () => {
            console.log('Five 9 callback server registered');

            this.startFive9Chat();
        });

        this.socket.on('create', (message: any) => {
            console.log('Five9 Callback create', message);
        });

        this.socket.on('message', (message: any) => {
            console.log('Five9 message', message);
            const response: Message = {
                stringMessage: message.text,
                timestamp: new Date().getTime(),
                source: 'FIVE9',
                type: 'STANDARD'
            };
            store.commit('Messages/appendMessage', response);
        });

        this.socket.on('accept', (message: any) => {
            console.log('Five9 message', message);
            const response: Message = {
                stringMessage: `${message.displayName} is now connected:`,
                timestamp: new Date().getTime(),
                source: 'FIVE9',
                type: 'TIME'
            };
            store.commit('Messages/appendMessage', response);
        });

        this.socket.on('typing', () => {
            if (
                store.state.Messages.messages[store.state.Messages.length - 1] &&
                store.state.Messages.messages[store.state.Messages.length - 1].type !== 'THINKING'
            ) {
                const thinkingMessage = {
                    source: 'BOT',
                    timestamp: new Date().getTime(),
                    html: thinkingHTML,
                    type: 'THINKING'
                };
                store.commit('Messages/appendMessage', thinkingMessage);
            }
            setTimeout(() => {
                if (
                    store.state.Messages.messages[store.state.Messages.length - 1] &&
                    store.state.Messages.messages[store.state.Messages.length - 1].type === 'THINKING'
                ) {
                    store.commit('Messages/removeLastMessage');
                }
            }, 5000);
        });

        this.socket.on('terminate', (message: any) => {
            console.log('Five9 message', message);
            const response: Message = {
                stringMessage: `${message.displayName} has disconnected:`,
                timestamp: new Date().getTime(),
                source: 'FIVE9',
                type: 'TIME'
            };
            store.commit('Messages/appendMessage', response);
            store.commit('Messages/setMessageMode', 'Interact');
        });
    }

    private startFive9Chat() {
        const attributes = {
            "Custom.Jacada_chatSessionId": store.state.InteractChatConfig.config.chatSessionId,
            "Custom.Jacada_chatSessionUniqueId": store.state.InteractChatConfig.config.chatSessionUniqueId,
            "Custom.Jacada_chatSkill": this.skill,
            "Custom.Jacada_tenant": store.state.InteractChatConfig.config.tenantId,
            "Custom.Jacada_domainName": store.state.InteractChatConfig.config.domainName,
            "Custom.Jacada_apiKey": store.state.InteractChatConfig.config.apiKey
        };
        console.log(attributes);
        axios.post('https://' + this.hostUrl + this.baseUrl + 'conversations', {
            tenantId: this.tenantId,
            campaignId: this.campaignId,
            contact: {
                firstName: this.userName? this.userName : 'Ascend User',
                email: this.userEmail
            },
            type: "SMS",
            callbackUrl: "https://five9-server.jacadademos.com/five9Callback",
            attributes: attributes,
            externalId: store.state.InteractChatConfig.config.chatSessionId
        }, {
            headers: {
                "Authorization": this.auth,
                "farmId": this.farmId,
                "Content-Type": "application/json",
                "User-Agent": "API Client"
            }
        }).then(() => {
            store.commit('Messages/setMessageMode', 'Five9');
        }).catch((e) => {console.error(e)});
    }
}